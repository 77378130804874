<template>
  <div class="job-list">
    <div class="content">
      <div class="filter">
        <span class="active"><i class="el-icon-monitor"></i> IT·互联网</span>
        <span><i class="el-icon-money"></i>金融</span>

        <span><i class="el-icon-office-building"></i>房地产·建筑</span>

        <span><i class="el-icon-school"></i>教育培训</span>

        <span><i class="el-icon-service"></i>娱乐传媒</span>

        <span><i class="el-icon-first-aid-kit"></i>医疗健康</span>

        <span><i class="el-icon-chat-dot-square"></i>法律咨询</span>

        <span><i class="el-icon-truck"></i>供应链·物流</span>

        <span><i class="el-icon-shopping-cart-1"></i>采购贸易</span>
      </div>

      <div class="list-wrap">
        <el-row :gutter="15">
          <el-col :span="8" v-for="i in 9" :key="i">
            <div class="job-box">
              <div class="title-price">
                <div class="title">罗湖新秀村直聘快递员包住配车</div>
                <span class="contact-btn">
                  <i class="el-icon-chat-dot-round"></i>
                </span>
                <div class="price">10-12K</div>
              </div>
              <div class="tags">
                深圳

                <span></span>
                经验不限
                <span></span>
                初中及以下
              </div>
              <div class="co">
                <div class="logo">
                  <img
                    src="http://dummyimage.com/30x30/4b5cc4"
                    alt=""
                    srcset=""
                  />
                </div>
                <span class="name"> 蚂蚁金服 </span>
                <span class="type"> 互联网 </span>
                <span class="vline"></span>
                <span class="level"> 不需要融资 </span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="more-btn">
        <el-button type="primary">查看更多</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.job-list {
  background: #f9f9f9;
  .content {
    width: 1200px;
    margin: 0 auto;

    .filter {
      display: flex;
      justify-content: space-around;
      background: #fff;

      margin: 15px;

      span {
        height: 50px;
        line-height: 50px;
        color: #8d92a1;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        border-bottom: 2px solid transparent;

        &:hover,
        &:active,
        &.active {
          border-bottom-color: red;
          color: red;
        }
      }
    }

    .list-wrap {
      .job-box {
        cursor: pointer;
        background: #fff;
        margin-bottom: 15px;
        padding: 16px 20px;

        &:hover,
        &:active {
          box-shadow: 5px 5px 20px rgb(0 0 0 / 5%);
        }

        .title-price {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            font-size: 15px;
            color: #333;
            margin-right: 8px;

            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .contact-btn {
            color: #409eff;
          }

          .price {
            flex: 1 1 auto;
            text-align: right;
            font-size: 15px;
            color: #fd7240;
          }
        }

        .tags {
          line-height: 28px;
          font-size: 13px;
          color: #999;

          max-width: 240px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            border-left: 1px solid #ededed;
            margin: 0 8px;
          }
        }

        .co {
          border-top: 1px solid #eef0f5;
          display: flex;
          align-items: center;
          margin-top: 8px;
          padding-top: 12px;

          font-size: 13px;
          color: #999;

          .logo {
            border: 1px solid #f3f5fb;
            img {
              width: 30px;
              height: 30px;
            }
          }

          .name {
            color: #333;
            margin-left: 10px;
            margin-right: 20px;
            max-width: 140px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .vline {
            display: inline-block;
            width: 1px;
            height: 12px;
            vertical-align: middle;
            background: #e0e0e0;
            margin: 0 10px;
          }
        }
      }
    }

    .more-btn {
      margin: 20px 0;
      text-align: center;
    }
  }
}
</style>