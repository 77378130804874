<template>
  <div class="title">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.title {
  font-size: 24px;
  text-align: center;
  position: relative;
  margin-bottom: 24px;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    vertical-align: 8px;
    margin: 0 10px;
    width: 50px;
    height: 1px;
    background: #d1d4db;
  }
}
</style>