<template>
  <div class="menu">
    <div class="content">
      <div class="left">菜单</div>
      <div class="right">
        <table>
          <tbody>
            <tr>
              <td>
                <img
                  src="http://dummyimage.com/215x145/50B347"
                  alt=""
                  srcset=""
                />
              </td>
              <td>
                <img
                  src="http://dummyimage.com/215x145/50B347"
                  alt=""
                  srcset=""
                />
              </td>
            </tr>

            <tr>
              <td>
                <img
                  src="http://dummyimage.com/215x145/50B347"
                  alt=""
                  srcset=""
                />
              </td>
              <td>
                <img
                  src="http://dummyimage.com/215x145/50B347"
                  alt=""
                  srcset=""
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.menu {
  background: #f9f9f9;
  padding: 20px 0;
  margin-bottom: 10px;
  .content {
    width: 1200px;
    margin: 0 auto;

    display: flex;

    .left {
      width: 380px;
      margin-right: 15px;
      background: #fff;
      padding: 20px;
      box-shadow: 5px 5px 15px rgb(3 0 0 / 4%);
    }

    .right {
      flex: 1 1 auto;
      table {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }

        td {
          overflow: hidden;
        }

        td:nth-child(1) {
          width: 35%;
        }

        td:nth-child(2) {
          width: auto;
        }

        tr:nth-child(1) {
          td {
            height: 110px;
          }
        }

        tr:nth-child(2) {
          td {
            height: 220px;
          }
        }
      }
    }
  }
}
</style>