<template>
  <div class="co-list">
    <div class="content">
      <el-row :gutter="15">
        <el-col :span="6" v-for="i in 8" :key="i">
          <div class="co-box">
            <div class="logo">
              <img src="https://dummyimage.com/60x60/00bc12" alt="" srcset="" />
            </div>
            <div class="name">阿里巴巴国际事业部</div>
            <div class="tags">已上市<span class="vline"></span>互联网</div>
            <div class="btn-wrap">
              <button>1234个热招职位</button>
            </div>
          </div>
        </el-col>
      </el-row>

      <div class="more-btn">
        <el-button type="primary">查看更多</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.co-list {
  .content {
    width: 1200px;
    margin: 0 auto;

    .co-box {
      background: #fff;
      margin-bottom: 15px;
      text-align: center;
      padding-top: 30px;

      .logo {
        margin-bottom: 14px;
        img {
          height: 58px;
          width: 58px;
          vertical-align: middle;
          border: 1px solid #f3f5fb;
          border-radius: 9px;
        }
      }

      .name {
        margin-bottom: 10px;
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .tags {
        height: 18px;
        line-height: 18px;
        color: #999;
        font-size: 12px;
        display: inline-block;
        white-space: nowrap;
        .vline {
          display: inline-block;
          width: 1px;
          height: 12px;
          vertical-align: middle;
          background: #e0e0e0;
          margin: 0 10px;
        }
      }

      .btn-wrap {
        margin-top: 10px;
        padding-bottom: 25px;
        button {
          width: 180px;
          height: 34px;
          color: #61687c;
          font-size: 12px;
          margin: 0 auto;
          text-align: center;
          border: 1px solid #e3e7ed;
          background-color: #fff;
        }
      }
    }

    .more-btn {
      margin: 20px 0;
      text-align: center;
    }
  }
}
</style>