<template>
  <div class="banner">
    <div class="content">
      <div class="left">
        <div class="title">找工作</div>
      </div>
      <div class="right">
        <div class="slogan">现在开始，快乐找工作</div>
        <div class="form">
          <el-input
            size="medium"
            placeholder="输入手机号码"
            style="width: 140px"
          ></el-input>

          <el-input
            size="medium"
            placeholder="验证码"
            style="width: 160px; margin: 0 10px"
          >
            <el-button slot="append">获取</el-button>
          </el-input>

          <el-button size="medium">登录/注册</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "banner",
};
</script>

<style lang="scss" scoped>
.banner {
  background: #5dd5c8;
  height: 120px;
  .content {
    width: 1200px;
    margin: 0 auto;

    padding: 0 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: #fff;

    .left {
      .title {
        margin-top: 20px;
        font-size: 34px;
        // font-weight: bold;
      }
    }

    .right {
      .slogan {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .form {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>