<template>
  <div class="job">
    <!-- <ori-header /> -->

    <banner />

    <search />

    <Menu />

    <Title>热招职位</Title>

    <job-list />

    <Title>热门企业</Title>

    <co-list />

    <!-- <Nav /> -->

    <!-- <page-foot /> -->
  </div>
</template>

<script>
// import PageFoot from "../../../components/ori-com/PageFoot.vue";
import Banner from "./Banner.vue";
import CoList from "./CoList.vue";
import JobList from "./Job List.vue";
import Menu from "./Menu.vue";
// import Nav from "./Nav.vue";
import Search from "./Search.vue";
import Title from "./Title.vue";
export default {
  components: {
    // PageFoot,
    Banner,
    Search,
    Menu,
    Title,
    JobList,
    CoList,
    // Nav
  },
};
</script>

<style lang="scss" scoped>
.job {
  background: #f9f9f9;
}
</style>